import { Component, Watch } from 'vue-property-decorator'
import { Container } from 'typedi'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
@Component({
  name: 'GtrCompanyDashboard',
  computed: {
    ...mapState('company', ['company', 'company_stats'])
  },
  filters: {
    removeUnderscore: function (value) {
      if (!value) return ''
      value = value.toString().replace(/_/, ' ')
      return value
    }
  }
})
export default class GtrCompanyDashboard extends GtrSuper {
  company!: Record<string, any>;

  eventCreateModal = false

  data () {
    return {
      loading: false,
      companyEvents: null,
      companyStats: null,
      statFilters: [
        '30 Days',
        '60 Days',
        '90 Days',
        'All Time'
      ],
      selectedStatFilter: 'All Time',
      eventFilters: [
        'Archived Events',
        'Past Events',
        'Live Events',
        'Upcoming Events'
      ],
      selectedEventFilter: 'Upcoming Events',
      moduleStats: [
        {
          type: 'register',
          icon: require('@/assets/img/modules/register.svg'),
          title: 'Registration',
          color: 'register-theme',
          background: 'bg-register-theme',
          stats: {
            completed_registrations: 0,
            gross_revenue: 0
          },
          visible: false
        },
        {
          type: 'leads',
          icon: require('@/assets/img/modules/leads.svg'),
          title: 'Lead Retrieval',
          color: 'leads-theme',
          background: 'bg-leads-theme',
          stats: {
            gross_revenue: 0,
            // scans: 0,
            app_activations: 0
          },
          visible: false
        },
        {
          type: 'track',
          icon: require('@/assets/img/modules/track.svg'),
          title: 'Attendance Tracking',
          color: 'track-theme',
          background: 'bg-track-theme',
          stats: {
            scans_used: 0,
            sessions: 0
            // purchased: 0
          },
          visible: false
        },
        {
          type: 'badges',
          icon: require('@/assets/img/modules/badges.svg'),
          title: 'Badge Printing',
          color: 'badges-theme',
          background: 'bg-badges-theme',
          stats: {
            badges_printed: 0
            // badge_records: 0
          },
          visible: false
        }
      ],
      companyInfoModalOpen: false,
      companyInfo: {
        uploadNewLogo: false,
        logo: null,
        name: null
      },
      submitting: false
    }
  }

  @Watch('company', { immediate: true })
  async onCompanyChange () {
    this.resetCompanyInfoForm()
    await this.fetchEvents()
    await this.fetchCompanyStats(null)
  }

  private async fetchEvents () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('event/loadEventsForCompany', { company_uuid: this.$route.params.uuid })
      if (response.data.length > 0) {
        const company = response.data.find((company: any) => company.uuid === this.$route.params.uuid)
        if (company) {
          this.$data.companyEvents = company.events
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCompanyStats (statParam) {
    try {
      this.$data.loading = true
      let payload
      if (statParam !== null) {
        payload = {
          company_uuid: this.$route.params.uuid,
          stat_param: statParam
        }
      } else {
        payload = { company_uuid: this.$route.params.uuid }
      }
      const response = await this.$store.dispatch('company/fetchCompanyStats', payload)
      // Chance for potential refactor
      if (response) {
        this.$data.companyStats = response
        if (this.$data.companyStats.module_counts.REGISTRATION > 0) {
          this.$data.moduleStats[0].visible = true
          this.$data.moduleStats[0].stats.completed_registrations = this.$options?.filters?.numberWithCommas(this.$data.companyStats.counts.REGISTRATION_COMPLETED)
          this.$data.moduleStats[0].stats.gross_revenue = this.$options?.filters?.toCurrency(this.$data.companyStats.counts?.REGISTRATION_GROSS_REVENUE)
        }
        if (this.$data.companyStats.module_counts.LEADS > 0) {
          this.$data.moduleStats[1].visible = true
          this.$data.moduleStats[1].stats.gross_revenue = this.$options?.filters?.toCurrency(this.$data.companyStats.counts?.LR_GROSS_REVENUE)
          this.$data.moduleStats[1].stats.app_activations = this.$options?.filters?.numberWithCommas(this.$data.companyStats.counts.LR_APP_ACTIVATIONS)
        }
        if (this.$data.companyStats.module_counts.SESSION_TRACKING > 0) {
          this.$data.moduleStats[2].visible = true
          this.$data.moduleStats[2].stats.scans_used = this.$options?.filters?.numberWithCommas(this.$data.companyStats.counts.TRACK_SCANS_TOTAL)
          this.$data.moduleStats[2].stats.sessions = this.$options?.filters?.numberWithCommas(this.$data.companyStats.counts.TRACK_SESSIONS)
        }
        if (this.$data.companyStats.module_counts.BADGES > 0) {
          this.$data.moduleStats[3].visible = true
          this.$data.moduleStats[3].stats.badges_printed = this.$options?.filters?.numberWithCommas(this.$data.companyStats.counts.BADGES_PRINTED)
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private updateCompanyStats () {
    let statParam = this.$data.selectedStatFilter
    const zeroIndex = this.$data.selectedStatFilter.indexOf('0')
    if (zeroIndex === -1) {
      this.fetchCompanyStats(null)
    } else {
      statParam = this.$data.selectedStatFilter.slice(0, zeroIndex + 1)
      this.fetchCompanyStats(statParam)
    }
  }

  get companyTotalParticipants () {
    let total = 0
    if (this.$data.companyEvents) {
      for (let i = 0; i < this.$data.companyEvents.length; i++) {
        total += this.$data.companyEvents[i].stats.participant_counts
      }
    }
    return total
  }

  get companyAdminUsers (): number | string {
    return this.company?.admin_users?.length || ''
  }

  get activatedModuleStats () {
    const activatedModuleStats = this.$data.moduleStats.filter(module => module.visible)
    return activatedModuleStats
  }

  get filteredEvents () {
    const events = this.$data.companyEvents
    if (!events) return []
    const archivedEvents: any[] = []
    const pastEvents: any[] = []
    const liveEvents: any[] = []
    const upcomingEvents: any[] = []
    for (let i = 0; i < events.length; i++) {
      const event = events[i]
      if (event.status === 'ARCHIVED') {
        archivedEvents.push(event)
      }
      if (event.status === 'ACTIVE') {
        if (!event.event_active && event.event_over && pastEvents.length < 4) {
          pastEvents.push(event)
        } else if (event.event_active && !event.event_over && liveEvents.length < 4) {
          liveEvents.push(event)
        } else if (!event.event_active && !event.event_over && upcomingEvents.length < 4) {
          upcomingEvents.push(event)
        }
      }
    }
    const eventFilter = this.$data.selectedEventFilter
    if (eventFilter === 'Archived Events') {
      return archivedEvents
    } else if (eventFilter === 'Past Events') {
      return pastEvents
    } else if (eventFilter === 'Live Events') {
      return liveEvents
    } else if (eventFilter === 'Upcoming Events') {
      return upcomingEvents
    } else {
      return events
    }
  }

  // TODO: create a mixin for shared functionality of event creation. See also: company-events.ts
  async handleEventCreatedEvent () {
    this.eventCreateModal = false
    await this.fetchEvents()
  }

  onFinishCreateEvent (value: boolean): void {
    this.eventCreateModal = value
  }

  handleCompanyInfoModalOpen () {
    this.$data.companyInfoModalOpen = true
  }

  handleCompanyInfoModalClose () {
    this.$data.companyInfoModalOpen = false
  }

  resetCompanyInfoForm () {
    this.$data.companyInfo.name = this.company?.name ? this.company.name : null
    this.$data.companyInfo.logo = this.company?.logo ? this.company.logo : null
    this.$data.companyInfo.uploadNewLogo = false
  }

  onLogoChange (payload: any) {
    if (payload) {
      this.$data.companyInfo.logo = payload
      this.$data.companyInfo.uploadNewLogo = true
    } else {
      this.$data.companyInfo.logo = this.company?.logo ? this.company.logo : null
      this.$data.companyInfo.uploadNewLogo = false
    }
  }

  async submitCompanyInfo () {
    const form = (this.$refs.updateCompanyInfoForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        await this.$store.dispatch('company/editCompany', { uuid: this.company.uuid, data: this.$data.companyInfo })
        Container.get(Notification).success('Company successfully updated.')
        this.handleCompanyInfoModalClose()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
